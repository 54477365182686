import React from 'react'
import { Layout, header, footer, main, container } from 'components/core/Layout'
import { Box, Flex, Card } from 'components/primitives'
import Text from 'components/core/Text'
import Link from 'components/core/Link'
import Logo from 'screens/shared/Logo'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import MenuButton from 'components/core/MenuButton'
import PinButton from 'components/core/PinButton'
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby'
import css from '@styled-system/css'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const NavLink = props => (
  <Link fontWeight='bold' fontSize={[2, 3]} color='initial' {...props} />
)

const FooterLink = props => (
  <Link fontSize={1} variant='underline' color='initial' {...props} />
)

const Header = ({ left, right, props }) => {
  const matches = useMediaQuery('(max-width:639px)')
  return (
    <Box {...header} flexDirection='column' pt={[3, 6]} pb={3} {...props}>
      <Flex
        alignItems='center'
        justifyContent={['space-between', 'center']}
        mb={[3, 5]}
        {...container}
      >
        {matches && left}
        <Link variant='none' color='initial' to='/'>
          <Logo />
        </Link>
        {matches && right}
      </Flex>
      <Flex
        mx='auto'
        justifyContent='center'
        alignItems='center'
        as='nav'
        {...container}
      >
        <NavLink mr={[3, 4]} to='/collections/all'>
          타일
        </NavLink>
        <NavLink mr={[3, 4]} to='/themes/red'>
          모음전
        </NavLink>
        <NavLink mr={[3, 4]} to='/blog'>
          블로그
        </NavLink>
        <NavLink mr={[3, 4]} to='/visit'>
          방문하기
        </NavLink>
        {/* <NavLink
          as='button'
          onClick={() => {
            window.Intercom('show')
          }}
        >
          문의하기
        </NavLink> */}
      </Flex>
    </Box>
  )
}
export const MenuList = props => {
  const {
    allAirtable: { edges }
  } = useStaticQuery(graphql`
    {
      allAirtable(
        filter: { table: { eq: "컬렉션" } }
        sort: { fields: data___order }
      ) {
        edges {
          node {
            data {
              name
              handle
            }
          }
        }
      }
    }
  `)
  const collections = edges.map(e => e.node.data)

  return (
    <Flex flexDirection='column' {...props}>
      {collections.map(c => (
        <MenuLink to={`/collections/${c.handle}`} key={c.handle}>
          {c.name}
        </MenuLink>
      ))}
    </Flex>
  )
}
const Footer = props => (
  <Box
    {...footer}
    {...container}
    flexDirection='column'
    pt={[3, 5]}
    pb={4}
    {...props}
  >
    <Text mb={3}>
      우리가 인테리어할 때 ‘이런 게 있었다면’ 하는 타일을 여기에 담았습니다.
    </Text>
    <Box as='nav' mb={1}>
      <FooterLink mr={2} to='/collections/all'>
        타일
      </FooterLink>
      <FooterLink mr={2} to='/themes/red'>
        모음전
      </FooterLink>
      <FooterLink mr={2} to='/blog'>
        블로그
      </FooterLink>
      {/* <FooterLink mr={2} to='/reviews'>
        후기
      </FooterLink> */}
      <FooterLink mr={2} to='/visit'>
        방문하기
      </FooterLink>
      {/* <FooterLink
        as='button'
        onClick={() => {
          window.Intercom('show')
        }}
      >
        문의하기
      </FooterLink> */}
    </Box>
    <Text fontSize={1}>
      © Copyright {new Date().getFullYear()}. All rights reserved.
    </Text>
  </Box>
)

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  }
})

const Main = props => <Box {...main} {...props}></Box>

const BaseLink = props => <GatsbyLink {...props} activeClassName='active' />
const MenuLink = props => (
  <BaseLink
    {...props}
    css={theme =>
      css({
        display: 'block',
        fontSize: 1,
        textDecoration: 'none',
        color: 'lightText',
        px: 3,
        py: 2,
        '&:hover': {
          color: 'text',
          fontWeight: 'bold'
        },
        '&.active': {
          color: 'text',
          fontWeight: 'bold'
        }
      })({ ...props, theme })
    }
  />
)

export default props => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const sideList = side => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <MenuList p={3} />
    </div>
  )

  return (
    <Layout>
      <Box
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          minHeight: 32,
          zIndex: 1000
        }}
      >
        <Card
          bg='accent'
          fontSize={1}
          px={2}
          py={1}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px solid',
            borderColor: '#b88f43',
            minHeight: 32
          }}
        >
          [공지] 1월 31일까지 연휴이니 참고 부탁드립니다. 감사합니다.
        </Card>
      </Box>
      <Box height={[32, 0]} css={{ visibility: 'hidden' }} />
      <Header
        left={
          <MenuButton
            css={{ ':hover': { backgroundColor: 'transparent' } }}
            onClick={toggleDrawer('left', true)}
          />
        }
        right={
          <PinButton
            as={GatsbyLink}
            css={{ ':hover': { backgroundColor: 'transparent' } }}
            to='/visit'
          />
        }
      />
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer>
      <Main {...props}>{props.children}</Main>
      <Footer />
    </Layout>
  )
}

export const MinimalLayout = props => (
  <Layout>
    <Main {...props}>{props.children}</Main>
  </Layout>
)
